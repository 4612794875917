<template>
    <v-app-bar app color="white" dense :elevation="1">
        <!-- Mobile Menu -->
        <div class="hidden-md-and-up">
            <v-menu>
                <template v-slot:activator="{ on }">
                    <v-app-bar-nav-icon v-on="on"></v-app-bar-nav-icon>
                </template>
                <v-list dense>
                    <v-list-item @click="$emit('fopen')">
                        <v-list-item-title><v-icon small>mdi-file</v-icon> Create New Report</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('fadd')">
                        <v-list-item-title><v-icon small>mdi-file-plus</v-icon> Add More Data</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('save')">
                        <v-list-item-title><v-icon small>mdi-content-save</v-icon> Save Report</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('savexlsx')">
                        <v-list-item-title><v-icon small>mdi-file-excel</v-icon> Export to Excel</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('savepdf')">
                        <v-list-item-title><v-icon small>mdi-file-pdf-box</v-icon> Preview PDF</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('frontpagepdf')">
                        <v-list-item-title><v-icon small>mdi-alpha-f-box</v-icon> PDF Front Page</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('reportpdf')">
                        <v-list-item-title><v-icon small>mdi-alpha-r-box</v-icon> PDF Report Only</v-list-item-title>
                    </v-list-item>
                    <v-divider inset></v-divider>

                    <v-list-item @click="$emit('viewtemplates')">
                        <v-list-item-title><v-icon small>mdi-palette-swatch</v-icon> My Templates</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('savetemplate')">
                        <v-list-item-title><v-icon small>mdi-content-save</v-icon> Save Current Template</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('savenewtemplate')">
                        <v-list-item-title><v-icon small>mdi-content-save-move</v-icon> Save To A New Template</v-list-item-title>
                    </v-list-item>
                     <v-divider inset></v-divider>

                    <v-list-item @click="$emit('viewcustomers')">
                        <v-list-item-title><v-icon small>mdi-account-group</v-icon> My Customers</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('viewreports')">
                        <v-list-item-title><v-icon small>mdi-file-document-multiple</v-icon> My Saved Reports</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('viewcalendar')">
                        <v-list-item-title><v-icon small>mdi-calendar</v-icon> My Calendar</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('viewabbrv')">
                        <v-list-item-title><v-icon small>mdi-format-color-text</v-icon> My Abbreviations</v-list-item-title>
                    </v-list-item>
                    <v-divider inset></v-divider>

                    <v-list-item @click="$emit('addemptyrows')">
                        <v-list-item-title><v-icon small>mdi-table-large</v-icon> Insert Empty Rows</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('manualentry')">
                        <v-list-item-title><v-icon small>mdi-table-plus</v-icon> Manual Entry</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('bulkreplace')">
                        <v-list-item-title><v-icon small>mdi-find-replace</v-icon> Find & Replace</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('applyabbrv')">
                        <v-list-item-title><v-icon small>mdi-format-text-rotation-none</v-icon> Apply Abbreviations</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('touppercase')">
                        <v-list-item-title><v-icon small>mdi-format-letter-case-upper</v-icon> To Upper Case</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('removeduplicates')">
                        <v-list-item-title><v-icon small>mdi-minus-circle</v-icon> Remove Duplicates</v-list-item-title>
                    </v-list-item>
                    <v-divider inset></v-divider>

                    <v-list-item @click="$emit('help')">
                        <v-list-item-title><v-icon small>mdi-help-box</v-icon> Hints</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('help-migration')">
                        <v-list-item-title><v-icon small>mdi-database</v-icon>Data Migration</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('help-support')">
                        <v-list-item-title><v-icon small>mdi-lifebuoy</v-icon> Support</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('help-about')">
                        <v-list-item-title><v-icon small>mdi-information</v-icon> About</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>

        <div class="d-flex align-center">
            <v-img alt="Logo" class="mr-2" contain src="../assets/logo.png" transition="scale-transition" width="200" />
        </div>

        <v-spacer></v-spacer>

        <div class="hidden-sm-and-down" style="margin-left: -200px;">
            <v-menu v-model="file" :close-on-content-click="true" :open-on-hover="true" :offset-y="true">
                <template v-slot:activator="{ on }">
                    <v-btn small v-on="on" plain>File <v-icon>mdi-chevron-down</v-icon></v-btn>
                </template>

                <v-list dense>
                    <v-list-item @click="$emit('fopen')">
                        <v-list-item-title><v-icon small>mdi-file</v-icon> Create New Report</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('fadd')">
                        <v-list-item-title><v-icon small>mdi-file-plus</v-icon> Add More Data</v-list-item-title>
                    </v-list-item>
                    <!--
                    <v-list-item @click="$emit('connect')">
                        <v-list-item-title><v-icon small>mdi-connection</v-icon> Connect</v-list-item-title>
                    </v-list-item>
                    -->
                    <v-list-item @click="$emit('save')">
                        <v-list-item-title><v-icon small>mdi-content-save</v-icon> Save Report</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('savexlsx')">
                        <v-list-item-title><v-icon small>mdi-file-excel</v-icon> Export to Excel</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('savepdf')">
                        <v-list-item-title><v-icon small>mdi-file-pdf-box</v-icon> Preview PDF</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('frontpagepdf')">
                        <v-list-item-title><v-icon small>mdi-alpha-f-box</v-icon> PDF Front Page</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('reportpdf')">
                        <v-list-item-title><v-icon small>mdi-alpha-r-box</v-icon> PDF Report Only</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <v-menu v-model="template" :close-on-content-click="true" :open-on-hover="true" :offset-y="true">
                <template v-slot:activator="{ on }">
                    <v-btn small v-on="on" plain>Template <v-icon>mdi-chevron-down</v-icon></v-btn>
                </template>

                <v-list dense>
                    <v-list-item @click="$emit('viewtemplates')">
                        <v-list-item-title><v-icon small>mdi-palette-swatch</v-icon> My Templates</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('savetemplate')">
                        <v-list-item-title><v-icon small>mdi-content-save</v-icon> Save Current Template</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('savenewtemplate')">
                        <v-list-item-title><v-icon small>mdi-content-save-move</v-icon> Save To A New Template</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <v-menu v-model="view" :close-on-content-click="true" :open-on-hover="true" :offset-y="true">
                <template v-slot:activator="{ on }">
                    <v-btn small v-on="on" plain>View <v-icon>mdi-chevron-down</v-icon></v-btn>
                </template>

                <v-list dense>
                    <v-list-item @click="$emit('viewcustomers')">
                        <v-list-item-title><v-icon small>mdi-account-group</v-icon> My Customers</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('viewreports')">
                        <v-list-item-title><v-icon small>mdi-file-document-multiple</v-icon> My Saved Reports</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('viewcalendar')">
                        <v-list-item-title><v-icon small>mdi-calendar</v-icon> My Calendar</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('viewabbrv')">
                        <v-list-item-title><v-icon small>mdi-format-color-text</v-icon> My Abbreviations</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <v-menu v-model="tools" :close-on-content-click="true" :open-on-hover="true" :offset-y="true">
                <template v-slot:activator="{ on }">
                    <v-btn small v-on="on" plain>Tools <v-icon>mdi-chevron-down</v-icon></v-btn>
                </template>

                <v-list dense>
                    <v-list-item @click="$emit('addemptyrows')">
                        <v-list-item-title><v-icon small>mdi-table-large</v-icon> Insert Empty Rows</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('manualentry')">
                        <v-list-item-title><v-icon small>mdi-table-plus</v-icon> Manual Entry</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('bulkreplace')">
                        <v-list-item-title><v-icon small>mdi-find-replace</v-icon> Find & Replace</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('applyabbrv')">
                        <v-list-item-title><v-icon small>mdi-format-text-rotation-none</v-icon> Apply Abbreviations</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('touppercase')">
                        <v-list-item-title><v-icon small>mdi-format-letter-case-upper</v-icon> To Upper Case</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('removeduplicates')">
                        <v-list-item-title><v-icon small>mdi-minus-circle</v-icon> Remove Duplicates</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <v-menu v-model="help" :close-on-content-click="true" :open-on-hover="true" :offset-y="true">
                <template v-slot:activator="{ on }">
                    <v-btn small v-on="on" plain>Help <v-icon>mdi-chevron-down</v-icon></v-btn>
                </template>

                <v-list dense>
                    <v-list-item @click="$emit('help')">
                        <v-list-item-title><v-icon small>mdi-help-box</v-icon> Hints</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('help-migration')">
                        <v-list-item-title><v-icon small>mdi-database</v-icon>Data Migration</v-list-item-title>
                    </v-list-item>
                     <v-list-item @click="$emit('help-support')">
                        <v-list-item-title><v-icon small>mdi-lifebuoy</v-icon> Support</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('help-about')">
                        <v-list-item-title><v-icon small>mdi-information</v-icon> About</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>

        <v-spacer></v-spacer>

        <v-menu v-model="profile" :close-on-content-click="true" :open-on-hover="true" :offset-y="true">
            <template v-slot:activator="{ on }">
                <v-btn icon small v-on="on" class="mr-1"><v-icon>mdi-account</v-icon></v-btn>
            </template>

            <v-list dense>
                <v-list-item @click="$emit('myprofile')">
                    <v-list-item-title><v-icon small>mdi-account</v-icon> My Profile</v-list-item-title>
                </v-list-item>
                <v-list-item @click="$emit('mypreferences')">
                    <v-list-item-title><v-icon small>mdi-tune-vertical</v-icon> Preferences</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item @click="logout">
                    <v-list-item-title><v-icon small>mdi-logout</v-icon> Logout</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-app-bar>
</template>
<script>
export default {
    data() {
        return {
            file: false,
            profile: false,
            tools: false,
            view: false,
            help: false,
            template:false,
        }
    },
    methods: {
        logout() {
            this.$store.dispatch('logOut');
        }
    }
}
</script>

<style>
.v-btn__content {
    opacity: 1 !important;
}
</style>