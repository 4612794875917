<template>
    <v-card-text>
        <crud
            label="My Abbreviations"
            model="abbreviations"
            :headers="headers"
            :conditions="[{client_id:{eq:$store.state.user.payload.id}}, {deleted:{'eq':'0'}}]"
            :initialValues="{client_id:$store.state.user.payload.id}"
            :key="rerender"
            fixed-header
        >
            <template v-slot:[`item.active`]="obj">
                <v-btn v-if="obj.item.active=='1'" icon small @click="toggleField('active', obj.item.id, '0')"><v-icon color="success lighten-1">mdi-circle</v-icon></v-btn>
                <v-btn v-else icon small @click="toggleField('active', obj.item.id, '1')"><v-icon color="error">mdi-circle</v-icon></v-btn>
            </template>
        </crud>
    </v-card-text>
</template>

<script>
import crud from "../components/CRUD.vue"
export default {
    components: {
        crud,
    },
    data() {
        return {
            headers: [
                {text:"ID", value:"id", isId:true, visible: false, editable: false},
                {text:"Client ID", value:"client_id", visible: false, editable: false},
                {text:"Abbreviation", value:"abbrv", mandatory: true, type: "uppercasetext", sm:4},
                {text:"Phrase", value:"phrase", mandatory: true, sm:6},
                {text:"Active", value:"active", type: "switch", fieldOptions: {"inset": true}, sm:2, slot: true},
            ],
            rerender: 0
        }
    },
    methods: {
        toggleField(field, id, value) {
            this.axios.post(this.ApiEndpoint, {
                operation: "update",
                table: "abbreviations",
                data: [{[field]: value, "id": id}],
            }).then(() => {
                this.rerender++
            })
        }
    }
}
</script>