<template>
    <v-container>
        <v-container>
            <v-row>
                <v-col cols="5">
                    <ImageUpload :ownerId="$store.state.user.payload.id" :value="profile.logo? ApiEndpoint + profile.logo : null" :placeholder="require('../assets/logo-placeholder.png')" class="ma-2" :uploadOnClick="profile.id == 3 ? false : true"></ImageUpload>
                </v-col>
                <v-col cols="7">
                    <v-text-field label="License Key" v-model="profile.license" :disabled="true"></v-text-field>
                    <v-text-field label="Expires" :value="profile.expiration || 'Never'" :disabled="true"></v-text-field>
                </v-col>
            </v-row>
        </v-container>
        <v-form v-model="valid">
            <v-row dense>
                <v-col cols="12">
                    <v-text-field label="Company Name" v-model="profile.name" :rules="[ruleMandatory]"></v-text-field>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12" md="4">
                    <v-text-field label="Address 1" v-model="profile.address" :rules="[ruleMandatory]"></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field label="Address 2" v-model="profile.address2" :rules="[ruleMandatory]"></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field label="Address 3" v-model="profile.address3" :rules="[ruleMandatory]"></v-text-field>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12" md="3">
                    <v-text-field label="City" v-model="profile.city" :rules="[ruleMandatory]"></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field label="State/County" v-model="profile.state" :rules="[ruleMandatory]"></v-text-field>
                </v-col>
                 <v-col cols="12" md="2">
                    <v-text-field label="Postal Code" v-model="profile.zip" :rules="[ruleMandatory]"></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-select label="Country" v-model="profile.country" :items="$store.state.countries" :rules="[ruleMandatory]"></v-select>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12" md="4">
                    <v-text-field label="Phone" v-model="profile.phone"></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field label="Email" v-model="profile.email"></v-text-field>
                </v-col>
                 <v-col cols="12" md="4">
                    <v-text-field label="Website" v-model="profile.website"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-progress-linear indeterminate class="ma-1" :active="!!loading"></v-progress-linear>
                    <v-alert text :color="alert.color" v-model="alert.show" tile dismissible>{{alert.text}}</v-alert>
                </v-col>
            </v-row>
            <v-row dense class="text-right">
                <v-col cols="12">
                    <v-btn color="primary" tile small dark @click="saveProfile()">Save</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>
<script>
import ImageUpload from "../components/ImageUpload.vue";

export default {
    components: {
        ImageUpload,
    },
    data() {
        return {
            profile: {
                id: null,
                license: null,
                name: null,
                address: null,
                address2: null,
                address3: null,
                city: null,
                state: null,
                zip: null,
                country: null,
                phone: null,
                email: null,
                website: null,
                logo: null,
                expiration: null,
            },
            valid: false,
            loading: false,
            alert: {
                show: false,
                text: null,
                color: null,
            },
            ruleMandatory: function(val) {
                return !!val || "This is a required field.";
            }
        }
    },
    methods: {
        saveProfile() {
            this.loading = true;
            this.alert.show = false;
            this.alert.text = null;
            this.alert.color = null;

            let app = this;
            this.axios.post(this.ApiEndpoint, {
                operation:"update",
                table:"clients",
                fields:["name", "address", "address2", "address3", "city", "state", "zip", "country", "phone", "email", "website"],
                data: [{"id": this.$store.state.user.payload.id, "name": this.profile.name, "address": this.profile.address, "address2": this.profile.address2, "address3": this.profile.address3, "city": this.profile.city, "state":this.profile.state, "country": this.profile.country, "zip":this.profile.zip, "phone": this.profile.phone, "email": this.profile.email, "website": this.profile.website}],
                where:[{id:{eq:this.$store.state.user.payload.id}}]
            }).then((res) => {
                if(res.data.data) {
                    app.alert.show = true;
                    app.alert.text = "Profile saved successfully."
                    app.alert.color = "green";
                }
                app.loading = false;
            });
        }
    },
    mounted() {
        let app = this;
        this.loading = true;
        this.axios.post(this.ApiEndpoint, {
            operation:"select",
            table:"clients",
            fields:["id", "license", "name", "address", "address2", "address3", "city", "state", "zip", "country", "phone", "email", "website", "logo", "expiration"],
            where:[{id:{eq:this.$store.state.user.payload.id}}]
        }).then(res => {
            app.profile = res.data.data[0];
            app.loading = false;
        });
    }
}
</script>