<template>
    <v-container fluid class="pa-0">
        <v-data-table
            :headers="visibleFields"
            :items="DataPG.data"
            :options.sync="options"
            :server-items-length="DataPG.totalRecords"
            :loading="loading"
            :show-expand="showExpand"
            :footer-props="{'itemsPerPageOptions': [75, 150, 300, -1]}"
            :items-per-page="($store.state.user.preferences ? $store.state.user.preferences.items_per_page : 75)"
            v-bind="$attrs"
            v-on="$listeners"
        >
            <template v-if="showExpand" v-slot:expanded-item="obj">
                <td :colspan="obj.headers.length" class="expander">
                    <slot name="expanded-item" v-bind="obj"></slot>
                </td>
            </template>
            <template v-slot:[`footer.prepend`]>
                <slot name="bottom"></slot>

                <v-btn v-if="canExport" small @click="exportXLSX()">
                    <v-icon small>mdi-file-excel</v-icon>Export
                </v-btn>
            </template>
            <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-btn v-if="canAdd" small tile v-on:click="editDialog=!editDialog">
                        <v-icon>mdi-plus</v-icon>Add new
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-toolbar-title>{{label}}</v-toolbar-title>
                    <v-spacer></v-spacer>

                    <div v-if="canSearch" style="width:20%">
                        <v-text-field
                            v-model="searchText"
                            :append-icon="searchCondition ? 'mdi-close': 'mdi-magnify'"
                            label="Search"
                            single-line
                            hide-details
                            @click:append="toggleSearch()"
                            @keyup.native.enter="doSearch()"
                            @keyup.native.esc="cancelSearch()"
                        ></v-text-field>
                    </div>
                    <v-btn v-if="canFilter" small icon class="ml-3" v-on:click="filtersBox=!filtersBox">
                        <v-icon>mdi-filter</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-container v-if="canFilter && filtersBox" fluid class="crud-filters">
                    <v-divider horizontal></v-divider>
                    <slot
                        name="filters"
                        v-bind:setConditions="setConditions"
                        v-bind:conditions="gridCondition"
                    ></slot>
                    <!--<v-spacer></v-spacer>
                    SHOULD BE PLACED ELSEWHERE
                    <v-select
                        multiple
                        label="Columns"
                        :items="headers"
                        return-object
                        v-model="adjustVisible"
                    ></v-select>-->
                </v-container>

                <v-divider horizontal></v-divider>
                <v-dialog v-model="editDialog" persistent eager max-width="60vw">
                    <!--<template v-slot:activator="{ on }">
                            <v-btn color="primary" dark class="mb-1" v-on="on">New Item</v-btn>
                    </template>-->
                    <v-card tile>
                        <v-card-title>
                            <span class="headline">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-form v-model="editFormValid" ref="crudform">
                                <v-row>
                                    <v-col
                                        v-for="(header) in editableFields"
                                        :key="header.value"
                                        :cols="header.cols ? header.cols : 12"
                                        :sm="header.sm ? header.sm : (header.cols ? null:6)"
                                        :md="header.md ? header.md : (header.cols || header.sm ? null:4)"
                                        :lg="header.lg ? header.lg : (header.cols || header.sm || header.md ? null:3)"
                                        :xl="header.xl ? header.xl : null"
                                    >
                                        <v-autocomplete
                                            v-if="header.type=='select'"
                                            v-model="fieldsToMutate[header.modelId ? header.modelId : header.value]"
                                            :label="header.text"
                                            :items="header.items"
                                            :item-value="header.modelFields ? header.modelFields.value : 'value'"
                                            :item-text="header.modelFields ? header.modelFields.text : 'text'"
                                            :rules="header.mandatory ? [ruleMandatory] : []"
                                            hide-details="auto"
                                            :clearable="!header.mandatory"
                                            v-bind="header.fieldOptions"
                                        >
                                            <template v-if="header.mandatory" v-slot:label>
                                                <span style="color:red">*</span>
                                                {{header.text}}
                                            </template>
                                        </v-autocomplete>

                                        <CheckboxList
                                            v-else-if="header.type=='checkboxlist'"
                                            v-model="fieldsToMutate[header.value]"
                                            :label="header.text"
                                            :items="header.items"
                                            :item-value="header.modelFields ? header.modelFields.value : 'value'"
                                            :item-text="header.modelFields ? header.modelFields.text : 'text'"
                                            :rules="header.mandatory ? [ruleMandatory] : []"
                                            hide-details="auto"
                                            v-bind="header.fieldOptions"
                                        >
                                            <template v-if="header.mandatory" v-slot:label>
                                                <span style="color:red">*</span>
                                                {{header.text}}
                                            </template>
                                        </CheckboxList>

                                        <datePicker
                                            v-else-if="header.type=='date'"
                                            v-model="fieldsToMutate[header.value]"
                                            :label="header.text"
                                            :rules="header.mandatory ? [ruleMandatory] : []"
                                            :mandatory="header.mandatory"
                                            show-current
                                            hide-details="auto"
                                            v-bind="header.fieldOptions"
                                        ></datePicker>

                                        <v-menu 
                                            v-else-if="header.type=='time'" 
                                            ref="menu" 
                                            :close-on-content-click="false" 
                                            :return-value.sync="fieldsToMutate[header.value]" 
                                            width="290px"
                                            :nudge-right="40"
                                            v-model="header.menu"
                                            offset-y
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field 
                                                    v-model="fieldsToMutate[header.value]"
                                                    :label="header.text"
                                                    prepend-icon="mdi-clock-outline" 
                                                    readonly 
                                                    v-on="on"
                                                    hide-details="auto"
                                                    :mandatory="header.mandatory"
                                                    :rules="header.mandatory ? [ruleMandatory] : []"
                                                >
                                                </v-text-field>
                                            </template>

                                            <v-time-picker
                                                v-if="header.menu"
                                                v-model="fieldsToMutate[header.value]"
                                                full-width
                                                :mandatory="header.mandatory"
                                                v-bind="header.fieldOptions"
                                                @click:minute="$refs.menu[0].save(fieldsToMutate[header.value])"
                                            >
                                            </v-time-picker>
                                        </v-menu>

                                        <v-switch
                                            v-else-if="header.type=='switch'"
                                            v-model="fieldsToMutate[header.value]"
                                            :label="header.text"
                                            hide-details="auto"
                                            v-bind="header.fieldOptions"
                                        ></v-switch>

                                        <label
                                            :for="'field_'+header.value"
                                            v-else-if="header.type=='radio'"
                                            class="v-label float-left"
                                        >
                                            <span v-if="header.mandatory" style="color:red">*</span>
                                            {{header.text}}
                                            <v-radio-group
                                                :id="'field_'+header.value"
                                                class="mt-0"
                                                v-model="fieldsToMutate[header.modelId ? header.modelId : header.value]"
                                                :rules="header.mandatory ? [ruleMandatory] : []"
                                                v-bind="header.fieldOptions"
                                            >
                                                <v-radio
                                                    v-for="(item,ik) in prepareItems(header.items)"
                                                    :key="ik"
                                                    :label="item[header.modelFields ? header.modelFields.text : 'text']"
                                                    :value="item[header.modelFields? header.modelFields.value : 'value']"
                                                    hide-details="auto"
                                                    v-bind="header.subfieldOptions"
                                                ></v-radio>
                                            </v-radio-group>
                                        </label>
                                        
                                        <v-textarea
                                            v-else-if="header.type=='textarea'"
                                            v-model="fieldsToMutate[header.value]"
                                            :label="header.text"
                                            :rules="header.mandatory ? [ruleMandatory] : []"
                                            hide-details="auto"
                                            v-bind="header.fieldOptions"
                                        >
                                            <template v-if="header.mandatory" v-slot:label>
                                                <span style="color:red">*</span>
                                                {{header.text}}
                                            </template>
                                        </v-textarea>

                                         <v-text-field
                                            v-else-if="header.type=='password'"
                                            v-model="fieldsToMutate[header.value]"
                                            type="password"
                                            :label="header.text"
                                            :rules="header.mandatory ? [ruleMandatory] : []"
                                            hide-details="auto"
                                            v-bind="header.fieldOptions"
                                        >
                                            <template v-if="header.mandatory" v-slot:label>
                                                <span style="color:red">*</span>
                                                {{header.text}}
                                            </template>
                                        </v-text-field>

                                        <UpperCaseTextInput
                                            v-else-if="header.type=='uppercasetext'"
                                            v-model="fieldsToMutate[header.value]"
                                            :label="header.text"
                                            :rules="header.mandatory ? [ruleMandatory] : []"
                                            :mandatory="header.mandatory"
                                            hide-details="auto"
                                            v-bind="header.fieldOptions"
                                        >
                                            <template v-if="header.mandatory" v-slot:label>
                                                <span style="color:red">*</span>
                                                {{header.text}}
                                            </template>
                                        </UpperCaseTextInput>

                                        <v-text-field
                                            v-else
                                            v-model="fieldsToMutate[header.value]"
                                            :label="header.text"
                                            :rules="header.mandatory ? [ruleMandatory] : []"
                                            hide-details="auto"
                                            v-bind="header.fieldOptions"
                                            v-mask="header.mask ? header.mask : nomask"
                                        >
                                            <template v-if="header.mandatory" v-slot:label>
                                                <span style="color:red">*</span>
                                                {{header.text}}
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="editDialog=false">Cancel</v-btn>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="save"
                                :disabled="!editFormValid"
                            >Save</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>
            <template v-for="fld in slotFields" v-slot:[fld]="obj">
                <slot :name="fld" v-bind="obj"></slot>
            </template>
            <template v-if="canEdit || canDelete" v-slot:[`item.actions`]="{item}">
                <v-progress-circular
                    v-if="loading"
                    color="primary"
                    indeterminate
                >Saving...</v-progress-circular>
                <v-container v-else class="text-no-wrap">
                    <v-icon v-if="canEdit" @click="editItem(item)">mdi-pencil</v-icon>
                    <v-icon v-if="canDelete" class="ml-3" @click="deleteItem(item)">mdi-delete</v-icon>
                    <v-tooltip v-if="errors.indexOf(item)>=0">
                        <template v-slot:activator="{ontt}">
                            <v-icon class="ml-3" color="red" v-on="ontt">mdi-alert</v-icon>
                        </template>
                        <span>Saving Error!</span>
                    </v-tooltip>
                </v-container>
            </template>
            <!--<template v-slot:no-data>
                    <v-btn color="primary" @click="initialize">Reset</v-btn>
            </template>-->
        </v-data-table>
        <v-dialog v-model="deleteDialog" persistent max-width="40vw">
            <v-card>
                <v-card-title>
                    <span class="headline">Delete</span>
                </v-card-title>

                <v-card-text>Are you sure you want to delete this record?</v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="deleteDialog=false">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteNow()">Delete</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar
            v-model="snackBar"
            top
            right
            :timeout="2000"
            :color="snackBarColor"
        >{{snackBarText}}</v-snackbar>
    </v-container>
</template>
<script>
import datePicker from "./DatePickerInput";
import UpperCaseTextInput from "./UpperCaseInput.vue";
import CheckboxList from "./CheckboxList.vue";
import xlsx from "xlsx";
import {mask} from 'vue-the-mask'
export default {
    inheritAttrs: false,
    components: {
        datePicker,
        UpperCaseTextInput,
        CheckboxList,
    },
    directives: {
        mask: (el, binding) => {
            if (!binding.value) return;
            mask(el, binding);
        }
    },
    props: {
        headers: Array,
        model: String,
        label: String,
        canAdd: { type: Boolean, default: true },
        canEdit: { type: Boolean, default: true },
        canDelete: { type: Boolean, default: true },
        canSearch: { type: Boolean, default: true },
        canFilter: { type: Boolean, default: false },
        canExport: { type: Boolean, default: false },
        canEmit: {type: Boolean, default: false},
        conditions: { type: Array, default: null },
        opts: {type: Object, default: function(){return {};}},
        showExpand: { type: Boolean, default: false },
        initialValues: {
            type: Object,
            default: function() {
                return {};
            }
        }
    },
    data() {
        return {
            //id:1,
            editDialog: false,
            deleteDialog: false,
            formTitle: "New record",
            fieldsToMutate: {},
            itemBeingEdited: {},
            itemBeingDeleted: {},
            DataPG: {},
            loading: false,
            errors: [],
            snackBar: false,
            snackBarText: "",
            snackBarColor: "success",
            oldHeaders: [], //Used to track changes

            gridCondition: [],
            searchText: null,
            searchCondition: null,

            havingCondition: null,

            filtersBox: false,
            adjustVisible: [],
            editFormValid: true,

            options: this.opts, // Grid Options - changed when sorted or paginated
            nomask: {
                mask: '*'.repeat(255),
                tokens: {
                    '*': { pattern: /./ }
                }
            },

            ruleMandatory: function(val) {
                return !!val?.length || "This is a required field!";
            }
        };
    },
    methods: {
        merge(array1, array2, fieldKey) {
            let map = [];
            if (array1 instanceof Array) {
                array1.forEach(v => {
                    map.push(v[fieldKey]);
                });
            }
            if (array2 instanceof Array) {
                array2.forEach(v => {
                    if (map.indexOf(v[fieldKey]) < 0) array1.push(v);
                });
            }
            return array1;
        },
        prepareItems(items) {
            if (!items) return items;
            let res = [];
            items.forEach(v => {
                if (v instanceof Object) {
                    res.push(v);
                } else res.push({ text: v, value: v });
            });
            return res;
        },
        setConditions(conditions) {
            this.gridCondition = conditions;
        },
        toggleSearch() {
            if (this.searchCondition) {
                this.cancelSearch();
            } else {
                this.doSearch();
            }
        },
        doSearch() {
            if (this.searchText == null) return;
            let where = [];
            let having = [];
            this.headers.forEach(v => {
                if (v.searchable) {
                    if(v.value.indexOf(".") == -1) where.push({[v.value]: { like: "%" + this.searchText + "%" }});
                    else  having.push({[v.value]: { like: "%" + this.searchText + "%" }});
                }
            });
           
            if(where.length > 1) this.searchCondition = [{ OR: where }];
            else this.searchCondition = where;
            if(having.length > 1) this.havingCondition = [{ OR: having}]
            else this.havingCondition = having;
            this.options.page = 1;

            this.fetch();
        },
        cancelSearch() {
            this.searchText = null;
            this.searchCondition = null;
            this.options.page = 1;

            this.fetch();
        },
        loadItems(field) {
            this.$set(field, "loading", true);
            let vars = {};

            if (field.search) {
                vars["opts"] = { page: 1, itemsPerPage: 20 };
                vars["wh"] = [
                    {
                        [field.modelFields.text]: {
                            like: "%" + field.search + "%"
                        }
                    }
                ];
            } else {
                vars["wh"] = [];
                vars["opts"] = {};
            }
            this.axios.post(this.ApiEndpoint, {
                table: field.model,
                operation: "select",
                fields: [field.modelFields.value, field.modelFields.text],
                where: vars["wh"],
                opts: vars["opts"],
            }).then(res => {
                    //console.log(res);
                    let arr = [];
                    if (field.items instanceof Array) {
                        arr = this.merge(
                            field.items,
                            res.data[field.model + "s"],
                            field.modelFields.value
                        );
                    } else arr = this._copy(res.data[field.model + "s"]);

                    this.$set(field, "items", arr);
                })
                .finally(() => {
                    this.$set(field, "loading", false);
                });
        },
        fetch() {
            let app = this;
            this.loading = true;
            this.axios.post(this.ApiEndpoint, {
                table: this.model,
                operation: "select",
                fields: this.gqlFields,
                where: this.totalConditions,
                having: this.havingCondition,
                opts: this.options,
            }).then(res => {
                app.DataPG = res.data;
            }).finally(() => {
                this.loading = false;
            })
        },
        save() {
            Object.keys(this.fieldsToMutate).forEach(k => {
                if (this.fieldsToMutate[k] === undefined)
                    this.fieldsToMutate[k] = null;
            });

            const theItem = this.itemBeingEdited;
            const inserting = Object.keys(theItem).length === 0;

            this.loading = true;
            this.axios.post(this.ApiEndpoint, {
                table: this.model,
                operation: inserting ? "insert" : "update",
                fields: this.gqlFields,
                data: [this.fieldsToMutate],
            }).then(res => {
                //console.log(res);
                if(inserting) {
                    //this.fetch();
                    this.snackBarColor = "success";
                    this.snackBarText = "Record successfully added!";
                    this.snackBar = true;
                }
                else {
                    this._setObject(theItem, res.data.data[0]); /*BUGS WHEN INITIALLY LOADED*/
                    //this.fetch();
                    this.snackBarColor = "success";
                    this.snackBarText = "Record successfully updated!";
                    this.snackBar = true;
                }
            }).catch(() => {
                if(inserting) {
                    this.snackBarColor = "error";
                    this.snackBarText = "Error: record could not be added!";
                    this.snackBar = true;
                }
                else {
                    this.snackBarColor = "error";
                    this.snackBarText = "Error: record could not be updated!";
                    this.snackBar = true;
                    this.errors.push(theItem);
                }
            }).finally(() => {
                this.fetch();
                if(this.canEmit) this.$emit("crudUpdate");
                this.loading = false;
            });
            this.editDialog = false;
        },
        deleteNow() {
            let theItem = this.itemBeingDeleted;
            this.loading = true;
            this.axios.post(this.ApiEndpoint, {
                table: this.model,
                operation: "delete",
                fields: [],
                where: [{id: {eq: theItem.id}}]
            }).then(() => {
                this.fetch();
                this.snackBarColor = "success";
                this.snackBarText = "Record deleted successfully.";
                this.snackBar = true;
            }).catch(() => {
                this.errors.push(theItem);
                this.snackBarColor = "error";
                this.snackBarText = "Error: Record could not be deleted.";
                this.snackBar = true;
            }).finally(() => {
                this.loading = false;
                if(this.canEmit) this.$emit("crudUpdate");
            });
            this.deleteDialog = false;
        },
        editItem(item) {
            this.itemBeingEdited = item;
            const pos = this.errors.indexOf(item);
            if (pos >= 0) this.errors.splice(pos, 1);
            this.editDialog = true;
        },
        deleteItem(item) {
            this.itemBeingDeleted = item;
            this.deleteDialog = true;
        },
        _setObject(subject, object) {
            Object.keys(object).forEach(k => {
                if (k.substring(0, 2) != "__") subject[k] = object[k];
                //this.$set(subject,k,object[k]);
            });
        },
        _copy(varToCopy) {
            let res;
            if (varToCopy instanceof Array) res = this._copyArray(varToCopy);
            else if (varToCopy instanceof Object)
                res = this._copyObject(varToCopy);
            else res = varToCopy;
            return res;
        },
        _copyObject(obj) {
            let res = {};
            Object.keys(obj).forEach(k => {
                if (obj[k] instanceof Array) res[k] = this._copyArray(obj[k]);
                else if (obj[k] instanceof Object)
                    res[k] = this._copyObject(obj[k]);
                else if (k.substring(0, 2) != "__") res[k] = obj[k];
            });
            return res;
        },
        _copyArray(arr) {
            let res = [];
            arr.forEach((v, k) => {
                if (v instanceof Array) res[k] = this._copyArray(v);
                else if (v instanceof Object) res[k] = this._copyObject(v);
                else res[k] = v;
            });
            return res;
        },
        getValue(obj, index) {
            if (obj instanceof Object) {
                let parts = index.split(".");
                let res = obj;
                parts.forEach(v => {
                    if (res instanceof Object) res = res[v];
                    else res = null;
                });
                return res;
            } else return null;
        },
        searchVal(arr, index, value, return_index) {
            if (arr instanceof Array) {
                let res = {};
                arr.forEach(v => {
                    if (v[index] == value) res = v;
                });
                return res[return_index];
            }
        },
        exportXLSX() {
            this.axios.post(this.ApiEndpoint, {
                table: this.model,
                operation: "select",
                fields: this.exportableFields,
                where: this.totalConditions,
            }).then((res) => {
                let h = [];
                this.headers.forEach(v => {
                    if(v.query !== false && v.visible !== false && v.text) h.push(v.text);
                });

                let workbook = xlsx.utils.book_new();
                let worksheet = xlsx.utils.aoa_to_sheet([h]);
                xlsx.utils.sheet_add_json(worksheet, res.data[this.model+"s"], {skipHeader: true, origin: -1});
                xlsx.utils.book_append_sheet(workbook, worksheet, "data");
                xlsx.writeFile(workbook, "export.xlsx");
            });
        }
    },
    watch: {
        conditions: {
            handler(n) {
                if (n) this.gridCondition = n;
            },
            immediate: true
        },
        gridCondition: {
            handler(n) {
                if(n) this.fetch()
            },
            deep: true
        },
        searchText(n) {
            if (!n) this.cancelSearch();
        },
        options: {
            handler() {
                this.fetch();
            },
            deep: true,
        },
        adjustVisible(n) {
            this.headers.forEach(v => {
                this.$set(v, "visible", false);
            });
            n.forEach(v => {
                this.$set(v, "visible", true);
            });
        },
        editDialog(n) {
            if (n) {
                // Edit form opened
                this.fieldsToMutate = {};
                if (Object.keys(this.itemBeingEdited).length === 0) {
                    // New Record
                    let hasMandatory = false;
                    this.formTitle = "New record";
                    this.headers.forEach(v => {
                        if ((v.editable !== false || this.initialValues[v.value] !== undefined) && v.query !== false) {
                            if (v.mandatory) hasMandatory = true;
                            if (
                                (v.type == "select" || v.type == "radio") &&
                                !v.items
                            )
                                this.loadItems(v);
                            if (v.modelId) {
                                this.fieldsToMutate[v.modelId] =
                                    this.initialValues[v.value] !== undefined
                                        ? this.initialValues[v.value]
                                        : undefined;
                            } else
                                this.fieldsToMutate[v.value] =
                                    this.initialValues[v.value] !== undefined
                                        ? this.initialValues[v.value]
                                        : undefined;
                        }
                    });
                    if (hasMandatory) this.editFormValid = false;
                    if (this.$refs.crudform)
                        this.$refs.crudform.resetValidation();
                } else {
                    // Edit Record
                    let hasEmptyMandatory = false;
                    this.formTitle = "Edit";
                    this.headers.forEach(v => {
                        if (
                            (v.editable !== false || v.isId || this.initialValues[v.value] !== undefined) &&
                            v.query !== false
                        ) {
                            if (
                                v.mandatory &&
                                this.getValue(this.itemBeingEdited, v.value) ==
                                    null
                            )
                                hasEmptyMandatory = true;
                            if (
                                (v.type == "select" || v.type == "radio") &&
                                !v.items
                            )
                                this.loadItems(v);
                            if (v.modelId) {
                                if (v.items instanceof Array) {
                                    let newItem = [];
                                    let vl = this.getValue(
                                        this.itemBeingEdited,
                                        v.value
                                    );
                                    if ((vl !== undefined) & (vl !== null)) {
                                        newItem = [
                                            {
                                                [v.modelFields.value]: this
                                                    .itemBeingEdited[v.modelId],
                                                [v.modelFields.text]: vl
                                            }
                                        ];
                                    }
                                    let merged = this.merge(
                                        v.items,
                                        newItem,
                                        v.modelFields.value
                                    );

                                    this.$set(v, "items", merged);
                                } else {
                                    let newItem = [];
                                    let vl = this.getValue(
                                        this.itemBeingEdited,
                                        v.value
                                    );
                                    if ((vl !== undefined) & (vl !== null)) {
                                        newItem = [
                                            {
                                                [v.modelFields.value]: this
                                                    .itemBeingEdited[v.modelId],
                                                [v.modelFields
                                                    .text]: this.getValue(
                                                    this.itemBeingEdited,
                                                    v.value
                                                )
                                            }
                                        ];
                                    }
                                    this.$set(v, "items", newItem);
                                }
                                this.fieldsToMutate[
                                    v.modelId
                                ] = this.itemBeingEdited[v.modelId];
                                
                            } else {
                                this.fieldsToMutate[
                                    v.value
                                ] = this.itemBeingEdited[v.value];
                            }
                        }
                    });
                    if (hasEmptyMandatory) this.editFormValid = false;
                    if (this.$refs.crudform)
                        this.$refs.crudform.resetValidation();
                }
            } else {
                //Edit form closed
                this.itemBeingEdited = {};
                this.editFormValid = true;
            }
        },
        headers: {
            handler(n) {
                //this.adjustVisible = [];
                n.forEach((v, k) => {
                    if (
                        this.oldHeaders[k] &&
                        this.oldHeaders[k].search != v.search
                    ) {
                        if (
                            v.search &&
                            v.search.length >= 2 &&
                            v.search !=
                                this.getValue(this.itemBeingEdited, v.value) &&
                            v.search !=
                                this.searchVal(
                                    v.items,
                                    v.modelFields.value,
                                    this.fieldsToMutate[v.modelId],
                                    v.modelFields.text
                                )
                        ) {
                            //console.log(v.items);
                            //console.log(v.modelFields.value);
                            //console.log(this.fieldsToMutate[v.modelId]);
                            this.loadItems(v);
                        }
                    }

                    //if (v.visible !== false) {
                    //this.adjustVisible.push(v);
                    //}
                });

                this.oldHeaders = this._copy(this.headers);
            },
            deep: true
        }
    },
    computed: {
        slotFields() {
            let res = [];
            this.headers.forEach(val => {
                if (val.slot === true) res.push("item." + val.value);
            });
            return res;
        },
        editableFields() {
            return this.headers.filter(
                val => val.editable !== false && val.query !== false
            );
        },
        gqlFields() {
            let flds = [];
            
            this.headers.forEach(v => {
                if (v.query !== false) {
                    if (v.value.indexOf(".") >= 0) {
                        let parts = v.value.split(".");
                        
                        let subq = {'operation': 'select', 'table': parts[0], fields:[parts[1]], where: [{[parts[0]+".id"]:{"eq":v.modelId}}]};
                        
                        flds.push(subq);
                        
                    } else flds.push(v.value);
                    //if (v.modelId) flds.push(v.modelId);
                }
            });
            return flds;
        },
        visibleFields() {
            let h = [];
            this.headers.forEach(v => {
                if (v.visible !== false) {
                    h.push(v);
                }
            });
            if (this.canEdit || this.canDelete)
                h.push({ text: "Actions", value: "actions", editable: false, sortable: false });
            return h;
        },
        exportableFields() {
            let flds = [];
            this.headers.forEach(v => {
                if (v.visible !== false && v.query !== false) {
                    if (v.value.indexOf(".") >= 0) {
                        let parts = v.value.split(".");
                        flds.push(`${parts[0]} { ${parts[1]} }`);
                    } else flds.push(v.value);
                    if (v.modelId) flds.push(v.modelId);
                }
            });
            return flds;
        },
        totalConditions() {
            if (this.gridCondition instanceof Array) {
                if (this.searchCondition instanceof Array) {
                    return this.gridCondition.concat(this.searchCondition);
                } else return this.gridCondition;
            } else
                return this.searchCondition instanceof Array
                    ? this.searchCondition
                    : [];
        }
    },
    mounted() {
        this.oldHeaders = this._copy(this.headers);
        this.headers.forEach(v => {
            if (v.visible !== false) this.adjustVisible.push(v);
        });

        this.fetch();
    },
};
/* 
        CRUD PROPERTIES 

            label (mandatory) - Sets GRIDs Label
            model (mandatory) - Defines GraphQL Model's name
            headers (array of objects) Described Below
            conditions (array of objects) "[{ deleted: { is: null } }]" - sets incomming conditions for the CRUD   

            can-add (default true) - CRUD can add record
            can-edit (default true) - CRUD can edit record
            can-delete (default true) - CRUD can delete record
            can-search (default true) - CRUD can search records
            can-filter (default false) - CRUD can filter records
            can-export (default false) - CRUD can export records to Excel

        HEADER PROPERTIES
        
            text (string : mandatory) - GRID Column's Label and Edit Form field's Label
            value (string : mandatory) - GraphQL query field name or object.fieldName as well as slot identifier(item.[value]).
            query (bool : default true) - Determines if that field will be used in GraphQL Query or not. Usefull for slotted columns.        
            slot (bool : default false) - Determines if that siled can be slotted. If true you can use named slot (item.[value])
            
            isId (bool : default false) - used to identify which fields id the ID
            editable (bool : default true) - If the field is editable or not
            visible (bool : default true) - If the field is visible in the GRID
            searchable (bool : default false) - If the field participate in search queries
            mandatory (bool : default false) - if the field is mandatory
            mask (string : default null) - applies mask to the input field (currently used for text fields only):
                '#': {pattern: /\d/},
                'X': {pattern: /[0-9a-zA-Z]/},
                'S': {pattern: /[a-zA-Z]/},
                'A': {pattern: /[a-zA-Z]/, transform: v => v.toLocaleUpperCase()},
                'a': {pattern: /[a-zA-Z]/, transform: v => v.toLocaleLowerCase()},
                '!': {escape: true}

            fieldOptions  (object) - pass option properties to the editable field
            subfieldOptions (object) - pass option properties to the editable subfield. currently used for radios only

            type (enum : default text) - Set the field type. Can be one of [select, checkboxlist, date,  time, switch, radio, text, password, textarea]
            items (array) When type is select you can define the items source for those fields 
            const: 

            model (string) - Sets the name of of the model used for data sorce for that field. Applicable for type select.
            modelId (string : mandatory when model is set) - this is the name of the query field used for reference. Usually an ID of the remote table. 
                When used the value property represents a subquery to a meaningfull column in referenced table. 
            modelFields (object : mandatory when model is set) - this is the model's value and text fields used for field's datasource.
            menu (object) - pass v-model for menu/dialog (used in time picker)

        SLOTS 

            v-slot:filters="{conditions, setConditions}"
            v-slot:item.[value]="{item, header, value}"

        */
</script>
<style>
.expander {
    background: white;
    border-left: 10px solid #e0e0e0;
    border-right: 10px solid #e0e0e0;
}
.v-data-table--fixed-header > .v-data-table__wrapper > table > thead > tr > th {
  position: sticky;
  top:0;
  z-index: 9999999;
}

.v-data-table--fixed-header > .v-data-table__wrapper {
  overflow: unset;
}
</style>