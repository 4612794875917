<template>
    <v-card-text>
        <crud
            label="My Customers / Locations"
            model="locations"
            :canSearch="true"
            :headers="headers"
            :conditions="[{'client_id': {'eq': $store.state.user.payload.id}}, {'deleted': {'eq': '0'}}, {'parent_id': {'eq': '0'}}]"
            :initialValues="{'client_id': $store.state.user.payload.id, 'parent_id': '0'}"
            :canEmit="true"
            :showExpand="true"
            @crudUpdate="$emit('reloadCustomers')"
            fixed-header
        >
            <template v-slot:[`item.name`]="obj">
                 <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <a v-on="on" @click.prevent="$emit('customerReports', obj.item.id)">{{ obj.item.name }}</a>
                    </template>
                    <span>View Reports</span>
                 </v-tooltip>
            </template>
            <template v-slot:[`expanded-item`]="obj">
                <crud
                    ref="sub"
                    label="Departments"
                    model="locations"
                    :canSearch="false"
                    :headers="subheaders"
                    :conditions="[{'client_id': {'eq': $store.state.user.payload.id}}, {'deleted':{'eq':'0'}}, {'parent_id': {'eq': obj.item.id}}]"
                    :initialValues="{'client_id': $store.state.user.payload.id, 'parent_id': obj.item.id}"
                    :canAdd="true"
                    :canEdit="true"
                    :canDelete="true"
                >
                     <template v-slot:[`item.name`]="subobj">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <a v-on="on" @click.prevent="$emit('customerReports', subobj.item.id)">{{ subobj.item.name }}</a>
                            </template>
                            <span>View Reports</span>
                        </v-tooltip>
                    </template>
                </crud>
            </template>
        </crud>
    </v-card-text>
</template>

<script>
import crud from "../components/CRUD.vue"
export default {
    components: {
        crud,
    },
    data() {
        return {
            locations: [],
            headers: [
                {text:"ID", value:"id", isId:true, visible: false, editable: false},
                {text:"Client ID", value:"client_id", visible: false, editable: false},
                {text:"Name", value:"name", mandatory: true, searchable: true, slot: true, sm: 6},
                {text:"Contact Person", value:"person", mandatory: false, searchable: true, sm: 6},
                {text:"Address", value:"address", mandatory: false, sm: 4},
                {text:"Address 2", value:"address2", mandatory: false, sm: 4},
                {text:"Address 3", value:"address3", mandatory: false, sm: 4},
                {text:"City", value:"city", mandatory: false, sm: 4},
                {text:"State/County", value:"state", mandatory:false, visible:false, editable:true, sm: 4},
                {text:"Postcode", value:"zip", mandatory: false, sm: 4},
                {text:"Country", value:"country", type:"select", items: this.$store.state.countries, mandatory:false, visible: false, sm: 12},
                {text:"Phone", value:"phone", mandatory: false, sm: 6},
                {text:"Email", value:"email", mandatory: false, sm: 6}
            ],
            subheaders: [
                {text:"ID", value:"id", isId:true, visible: false, editable: false},
                {text:"Client ID", value:"client_id", visible: false, editable: false},
                {text:"Parent", value: "parent_id", mandatory: false, visible: false, editable:false},
                {text:"Department", value:"name", mandatory: true, searchable: true, slot: true, sm: 4},
                {text:"Contact Person", value:"person", mandatory: false, searchable: true, sm: 4},
                {text:"Address", value:"address", mandatory: false, sm: 4},
                {text:"Address 2", value:"address2", mandatory: false, sm: 4},
                {text:"Address 3", value:"address3", mandatory: false, sm: 4},
                {text:"City", value:"city", mandatory: false, sm: 4},
                {text:"State/County", value:"state", mandatory:false, visible:false, editable:true, sm: 4},
                {text:"Postcode", value:"zip", mandatory: false, sm: 4},
                {text:"Country", value:"country", type:"select", items: this.$store.state.countries, mandatory:false, visible: false, sm: 12},
                {text:"Phone", value:"phone", mandatory: false, sm: 6},
                {text:"Email", value:"email", mandatory: false, sm: 6}
            ],
        }
    },
}
</script>